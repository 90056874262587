import * as Mapbox from 'mapbox-gl'
import * as Moment from 'moment'
import * as Numeral from 'numeral'
import * as JSZip from 'jszip'
import * as _ from 'lodash'
import 'numeral/locales'

import { DROPDOWN } from './data/constants'
import { Point } from './point'
import { Project } from './project'
import { ReportHeavydyn } from './reportHeavydyn'
import { Unit } from './unit'
import { Threshold } from './threshold'

export class Factory {
    public database: any

    public order: object[] = []

    public name: string = ''

    public state: string = 'import.chooseFile'

    private type: string = ''

    constructor() {
        Numeral.locale('fr')
        Moment.locale('fr')
    }

    // ---
    // PUBLIC
    // ---

    public async import(files: File[], project: Project) {
        if (files.length > 1) {
            this.state = 'import.tooManyFiles'
            return
        }

        // this.state = 'import.loading';
        const file = files[0]

        project.clean()

        let FILE_EXTENSION: string

        FILE_EXTENSION = file.name.split('.').pop() || ''
        this.name = file.name.slice(0, file.name.length - FILE_EXTENSION.length - 1)

        switch (FILE_EXTENSION) {
            case 'txt':
                const FILE_READER: FileReader = new FileReader()

                FILE_READER.onload = async () => {
                    const RESULT: string = FILE_READER.result as string
                    this.processTxt(project, RESULT)
                }

                FILE_READER.readAsText(file)

                break
            case 'rptz':
                const JSZIP = new JSZip()
                JSZIP.loadAsync(file).then(
                    (zip: any) => {
                        Object.keys(zip.files).forEach((key: string) => {
                            ;[, FILE_EXTENSION] = key.split('.')
                            switch (FILE_EXTENSION) {
                                case 'txt':
                                    zip.files[key].async('string').then((content: any) => {
                                        this.processTxt(project, content)
                                    })
                                    break
                                case 'xlsx':
                                case 'xls':
                                    zip.files[key].async('arraybuffer').then((content: any) => {
                                        const blob = new Blob([content], { type: 'text/plain' })
                                        project.currentTemplate = {
                                            name: key,
                                            content: blob,
                                        }
                                    })
                                    break
                                case 'png':
                                case 'jpg':
                                case 'jpeg':
                                    zip.files[key].async('arraybuffer').then((content: any) => {
                                        const blob = new Blob([content], { type: 'image/png' })

                                        const reader = new FileReader()
                                        reader.readAsDataURL(blob)
                                        reader.onloadend = () => {
                                            if (project.reports.current) {
                                                project.reports.current.screenshots.push(
                                                    reader.result as string
                                                )
                                            }
                                        }
                                    })
                                    break
                                default:
                                    break
                            }
                        })
                    },
                    () => {}
                )
                break
            default:
                break
        }
    }

    private processTxt(project: Project, content: string): void {
        try {
            if (project._mapview._map) {
                this.order = []
                this.convertDatabaseToObject(content)
                this.setProjectParameters(project._mapview._map, project)
                this.typeify(this.database, project)

                // this.fixDatabase();

                this.importDatabase(project._mapview._map, project)
                console.log(project)
                project.update()
                this.state = 'import.chooseFile'
            }
        } catch (e) {
            console.log(e)
            this.state = 'import.error'
        }
    }

    private fixDatabase() {
        const nbDropExpected: number = this.database.Etapes.length

        let i = 0
        let drop: any
        let previousDrop: any

        let indexFirstPoint = 0

        while ((this.order[indexFirstPoint] as any).name !== 'ReportPoints') {
            indexFirstPoint++
        }

        console.log(indexFirstPoint)
        console.log(_.cloneDeep(this.order).map((x: any) => x.name))

        while (i < this.database.Drops.length) {
            drop = this.database.Drops[i]

            if (
                previousDrop &&
                previousDrop.Numero.value !== drop.Numero.value - 1 &&
                previousDrop.Numero.value !== nbDropExpected
            ) {
                const dropCopy = _.cloneDeep(previousDrop)
                dropCopy.Numero.value += 1
                this.database.Drops.splice(i, 0, dropCopy)
                const indexOrder = indexFirstPoint + i + Math.floor(i / nbDropExpected) + 1
                console.log(indexOrder)
                this.order.splice(indexOrder, 0, _.cloneDeep(this.order[indexOrder - 1]))
                previousDrop = dropCopy
            } else {
                previousDrop = drop
            }

            i++
        }

        console.log(this.order.map((x: any) => x.name))
    }

    // ---
    // PRIVATE
    // ---

    // Heavydyn
    private setProjectParameters(map: Mapbox.Map, project: Project): void {
        const REPORT: ReportHeavydyn = new ReportHeavydyn(
            map,
            this.database.Dossiers.UnitDeplNom,
            this.database.Dossiers.UnitForceNom
        )

        REPORT.values.current = undefined
        const REGEX: RegExp = /^D-?[0-9]+$/
        const REGEX2: RegExp = /^Force(m|M)ax$/

        // eslint-disable-next-line no-restricted-syntax
        for (const element in this.database.ArrayResult) {
            if (REGEX.test(element)) {
                REPORT.values.list.push(element)
                if (element === 'D0') {
                    REPORT.values.current = element
                }
            } else if (REGEX2.test(element)) {
                // TODO: Remove that specific case
                REPORT.values.list.push('ForceMax')
            }
        }

        if (!REPORT.values.current) {
            ;[REPORT.values.current] = REPORT.values.list
        }

        project.reports.current = REPORT
        project.reports.list.push(project.reports.current)

        REPORT.thresholds.list.forEach((threshold: Threshold) => {
            if (threshold.name === this.database.Plateformes.ClassDeflection) {
                REPORT.thresholds.current = threshold
            }
        })

        this.type = this.database.Database.Logiciel
    }

    private convertDatabaseToObject(result: string): void {
        const LINES: string[] = result.split('\n')
        const OBJ: any = {}
        let index: string = ''

        const RGX_NEG_DIST: RegExp = /^D_[0-9]+$/
        const RGX_POS_DIST: RegExp = /^D[0-9]+$/

        // eslint-disable-next-line no-restricted-syntax
        for (const LINE of LINES) {
            let split: string[]
            if (LINE.includes('\t')) {
                split = LINE.trim().split('\t')
            } else {
                split = LINE.trim().split(' ')
            }

            if (split[0] === 'TABLE') {
                ;[, index] = split
                this.order.push({
                    name: index,
                    content: [],
                })

                if (Array.isArray(OBJ[index])) {
                    OBJ[index].push({})
                } else if (OBJ[index]) {
                    OBJ[index] = [OBJ[index], {}]
                } else {
                    OBJ[index] = {}
                }
            } else {
                ;(this.order[this.order.length - 1] as any).content.push(split[0])

                if (RGX_NEG_DIST.test(split[0])) {
                    split[0] = split[0].replace(/_0*/, '-')
                } else if (RGX_POS_DIST.test(split[0])) {
                    split[0] = split[0].replace(/D0*/, 'D')
                    if (split[0].length === 1) {
                        split[0] = 'D0'
                    }
                }

                if (!split[1]) {
                    split[1] = ''
                }

                for (let index2 = 1; index2 < split.length; index2++) {
                    const VALUE: any = split[index2]
                    let obj: any

                    if (Array.isArray(OBJ[index])) {
                        obj = OBJ[index][Object.keys(OBJ[index]).length - 1]
                    } else {
                        obj = OBJ[index]
                    }

                    if (Array.isArray(obj[split[0]])) {
                        obj[split[0]].push(VALUE)
                    } else if (obj[split[0]]) {
                        obj[split[0]] = [obj[split[0]], VALUE]
                    } else {
                        obj[split[0]] = VALUE
                    }
                }
            }
        }

        console.log(OBJ)
        this.database = OBJ
    }

    // Heavydyn
    private static convertEntryToObject(
        report: ReportHeavydyn,
        fieldName: string,
        fieldValue: string
    ): AdvancedType {
        const TO_NUMBER: (val: string) => number = (val) => Numeral.default(val).value()

        switch (fieldName) {
            case 'Commentaire':
                return {
                    kind: 'longString',
                    value: fieldValue,
                }
            case 'GTR':
            case 'ClassDeflection':
                return {
                    kind: 'dropdownFixed',
                    hidden: true,
                    value: {
                        current: fieldValue,
                        list: DROPDOWN[fieldName],
                    },
                }
            case 'Type':
            case 'Couche':
            case 'Materiau':
            case 'Etat':
                return {
                    kind: 'dropdown',
                    value: {
                        current: fieldValue,
                        list: DROPDOWN[fieldName],
                    },
                }
            case 'SeuilDeflection':
                return {
                    kind: 'unit',
                    readonly: true,
                    hidden: true,
                    value: new Unit(TO_NUMBER(fieldValue), report.distance, 2),
                }
            case 'ForceMax':
            case 'Forcemax':
            case 'ValeurDrop':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), report.force),
                }
            case 'FreqAcqu':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 'Hz', 2),
                }
            case 'DPlaque':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), report.distance, 3),
                }
            case 'PositionCapteurs':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 'mm', 3),
                }
            case 'Latitude':
            case 'Longitude':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 'deg', 6),
                }
            case 'DOP':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 'm'),
                }
            case 'PulseTime':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 's'),
                }
            case 'PreTrig':
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 's', 2),
                }
            default:
                break
        }

        const RGX_BOOL: RegExp = /^((T|t)rue|(F|f)alse)$/
        const RGX_DATE: RegExp = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}( [0-9]{2}:[0-9]{2})?$/
        const RGX_DIST: RegExp = /^D-?[0-9]+$/
        const RGX_FORC: RegExp = /^Force.*$/
        const RGX_NUM: RegExp = /^-?[0-9]+((,|\.| )[0-9]+)*$/
        const RGX_TEMP: RegExp = /^T(air|man|surf)$/
        const RGX_UNIT: RegExp = /^Unit.*$/
        const RGX_POINTS_OK: RegExp = /^PointsOK_((?!Nombre).)+$/

        switch (true) {
            case RGX_TEMP.test(fieldName): {
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), 'degC'),
                }
            }
            case RGX_POINTS_OK.test(fieldName):
            case RGX_DIST.test(fieldName): {
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), report.distance),
                }
            }
            case RGX_NUM.test(fieldValue): {
                return {
                    kind: 'number',
                    value: TO_NUMBER(fieldValue),
                }
            }
            case RGX_FORC.test(fieldName): {
                return {
                    kind: 'unit',
                    value: new Unit(TO_NUMBER(fieldValue), report.force),
                }
            }
            case RGX_BOOL.test(fieldValue): {
                if (fieldValue.toLowerCase() === 'true') {
                    return {
                        kind: 'boolean',
                        hidden: true,
                        value: true,
                    }
                } else {
                    return {
                        kind: 'boolean',
                        hidden: true,
                        value: false,
                    }
                }
            }
            case RGX_DATE.test(fieldValue): {
                // eslint-disable-next-line no-case-declarations
                let format: string = 'L'

                if (fieldValue.split(' ').length === 2) {
                    format += ' hh:mm'
                }

                return {
                    kind: 'date',
                    readonly: true,
                    oldValue: fieldValue,
                    value: Moment.default(fieldValue, format),
                }
            }
            case RGX_UNIT.test(fieldName): {
                return {
                    kind: 'string',
                    hidden: true,
                    value: fieldValue,
                }
            }
            default: {
                return {
                    kind: 'string',
                    value: fieldValue,
                }
            }
        }
    }

    private importDatabase(map: Mapbox.Map, project: Project): void {
        project.information = this.database.Dossiers
        project.informationPlatform = this.database.Plateformes

        if (project.reports.current) {
            const report = project.reports.current

            report.information = this.database.PVs

            this.database.ReportPoints.forEach((information: any) => {
                const nbDropPerPoint: number =
                    this.database.Drops.length / this.database.ReportPoints.length
                const index: number = nbDropPerPoint * report.points.children.length

                const drops: object[] = this.database.Drops.slice(index, index + nbDropPerPoint)
                drops.forEach((drop: any) => {
                    drop.NumeroReportPoints = information.Numero
                })

                const POINT = new Point(information, drops)
                POINT.setOriginalNumber(this.database.ReportPoints.indexOf(information) + 1)
                POINT.values = POINT.drops[POINT.drops.length - 1]
                report.points.addPoint(POINT)
            })

            report.addPointsToMap(map)
        }
    }

    private typeify(child: any, project: Project, name?: string): void {
        // eslint-disable-next-line no-restricted-syntax
        for (const PROP in child) {
            if (typeof child[PROP] === 'string' && project.reports.current) {
                if (this.type === 'Heavydyn') {
                    const report: ReportHeavydyn = project.reports.current as ReportHeavydyn
                    child[PROP] = Factory.convertEntryToObject(report, name || PROP, child[PROP])
                }
            } else if (Array.isArray(child[PROP])) {
                this.typeify(child[PROP], project, PROP)
            } else {
                this.typeify(child[PROP], project)
            }
        }
    }
}
