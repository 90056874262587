

































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class C extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION || '1.0'
}
